import 'popper.js';
import 'jquery';
import 'bootstrap';
import './bootstrap-alerts';
import 'parsleyjs';

$(function () {
    /**
     * On search icon (.toggle-search) collapse down the #search-container
     */
    $('.toggle-search').on('click', function (e) {
        e.preventDefault();

        $('#search-container').on('shown.bs.collapse', function () {
            $('#search-container .form-control').focus();
        })
    });
});

$(window).scroll(function(){
    if($(document).scrollTop() > 0) {
        $('header').addClass('is-stuck');
    }
    else {
        $('header').removeClass('is-stuck');
    }
});

let loginForm = $('#mccs-login-form');
if (loginForm.length > 0) {
    loginForm.on('submit', function (e) {
        e.preventDefault();

        /* check the data is correct before we send a post request */
        if (!$(this).parsley().isValid())
            return;

        let email = jQuery(this).find('#email').val();
        let password = jQuery(this).find('#password').val();
        let redirection = jQuery(this).find('#redirection').val();

        jQuery.post(mccs_login_ajax, {
            action: 'post_mccs_login',
            email: email,
            password: password,
            redirection: redirection
        }, function (response) {
            // ??
        }).done(function (response) {
            window.location = response.data.redirection;
        }).fail(function (response) {
            let json = response.responseJSON;

            console.log(json);

            if (json.redirection != null && json.redirection.length > 0) {
                $('#alert-container').bootstrapAlert({
                    type: 'success',
                    message: 'Welcome back! Redirecting to members area...',
                    class: 'mb-3'
                });

                window.location = json.redirection;
            } else {
                $('#alert-container').bootstrapAlert({
                    type: 'danger',
                    message: json.data.error,
                    class: 'mb-3'
                });
            }
        });
    });

    loginForm.parsley({
        errorClass: 'is-invalid',
        successClass: 'is-valid',
        errorsWrapper: '<div class="invalid-feedback"></div>',
        errorTemplate: '<span></span>',
        trigger: 'change'
    });
}

/* forgotten password page validation */
let lostPasswordForm = $('#lostpasswordform');
if (lostPasswordForm.length > 0) {
    lostPasswordForm.parsley({
        errorClass: 'is-invalid',
        successClass: 'is-valid',
        errorsWrapper: '<div class="invalid-feedback"></div>',
        errorTemplate: '<span></span>',
        trigger: 'change'
    });
}

/* reset password page validation */
let resetPasswordForm = $('#resetpasswordform');
if (resetPasswordForm.length > 0) {
    resetPasswordForm.parsley({
        errorClass: 'is-invalid',
        successClass: 'is-valid',
        errorsWrapper: '<div class="invalid-feedback"></div>',
        errorTemplate: '<span></span>',
        trigger: 'change'
    });
}

const evidenceBodyText = document.getElementsByClassName('evidence-body-text');
const seeMoreToggle = document.getElementsByClassName('see-more-toggle');
for (let i = 0; i < evidenceBodyText.length; i++) {
    if (evidenceBodyText[i].offsetHeight > 70) {
        evidenceBodyText[i].style.height = "70px";
        seeMoreToggle[i].innerText = "See More"
        seeMoreToggle[i].addEventListener('click', function(e) {
            if (this.parentNode.firstElementChild.style.height === "70px") {
                this.parentNode.firstElementChild.style.height = "100%";
                this.parentNode.lastElementChild.innerText = "Close"
            } else {
                this.parentNode.firstElementChild.style.height = "70px";
                this.parentNode.lastElementChild.innerText = "Read More"
            }
        })
    }
}

document.addEventListener('DOMContentLoaded', function() {
    let ffSubmitButton = document.querySelector('.frm_button_submit');

    if(ffSubmitButton) {
        ffSubmitButton.classList.add('btn', 'btn-lime', 'btn-lg', 'mb-5');
        ffSubmitButton.innerText = 'Search';
    }
});